<template>
  <section></section>
</template>

<script>
export default {
  name: 'dashboard'
}
</script>

<style scoped>

</style>